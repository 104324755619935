import React from "react";
import { object } from "prop-types";
import { Layout } from "../components/";

const GenericPage = ({ pageContext }) => {
    return (
        <>
            <Layout>
                <div className="mx-auto my-10 mt-60 sm:mt-40 prose lg:prose-lg max-w-100ch">
                    <h1>{pageContext.title}</h1>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: pageContext.content,
                        }}
                    />
                </div>
            </Layout>
        </>
    );
};

GenericPage.propTypes = {
    pageContext: object,
};

export default GenericPage;
